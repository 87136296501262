import React from "react";
import styled from "styled-components";
import {Routes, Route, Navigate} from "react-router-dom";
import MainLayout from "../../components/organismes/layouts/MainLayout";
import Dashboard from "../Dashboard/Dashboard";
import {useGetInfoUser} from "../../features/user/user.hooks";

interface ApplicationCtrlProps{
    className?:string;
}

const ApplicationCtrl = ({className}:ApplicationCtrlProps)=>{
    const UserQuery = useGetInfoUser();
    return(
        <div className={`wrapApp ${className}`}>
            <Routes>
                <Route element={<MainLayout UserQuery={UserQuery}/>}>
                    <Route path="" element={<Navigate to="dashboard" />} />
                    <Route path="/dashboard" element={<Dashboard/>} />
                </Route>
            </Routes>
        </div>
    )
}

const Application = styled(ApplicationCtrl)`
    
`
export default Application;