import React from "react";
import styled from "styled-components";

interface NibeBlockMetrixCtrlProps{
    className?:string;
    title?:string;
    children:React.ReactNode;
}

const NibeBlockMetrixCtrl = ({className, title, children}:NibeBlockMetrixCtrlProps)=>{
    return (
        <div className={`BlockMetrix ${className}`}>
            <div className={"in-block"}>
                <div className={`title`}>{!!title ? title : 'Block Metrix'}</div>
                <div className={`content`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

const NibeBlockMetrix = styled(NibeBlockMetrixCtrl)`
    width: 100%;
  padding: 0.25rem;
  .in-block{
    height: 100%;
    display: flex;
    flex-direction: column;
      border:solid ${props=>props.theme.Secondary} 1px;
      border-radius: 6px;
        .title{
          text-align: center;
          padding: 0.5rem;
          border-bottom: solid ${props=>props.theme.Secondary} 1px;;
        }
        .content{
          flex-grow: 1;
          padding: 1rem;
        }
  }
`

export default NibeBlockMetrix;