import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {addClient, getAllClient} from "./client.services";
import {toast} from "react-toastify";


export const useGetAllClient = ():UseQueryResult<Client[], Error>=>{
    return useQuery<Client[], Error>(["clients"], getAllClient, {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useAddClient = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ClientFormData)=>{
            return addClient(datas);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["clients"]);
            },
            onError:(error:any)=>{
                toast.error("Erreur : "+error.message)
            }
        }
    )
}