import React from "react";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import LoginForm from "../Forms/LoginForm";

interface LoginCtrlProps{
    className?:string;
}

const LoginCtrl = ({className}:LoginCtrlProps)=>{
    const location = useLocation();
    return (
        <div className={`LoginPage ${className}`}>
            <div className={`wrap-connexion`}>
                <LoginForm location={location}/>
            </div>
        </div>
    )
}

const Login = styled(LoginCtrl)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  overflow: hidden;
`

export default Login;