import React, {useMemo, useState} from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import NibeBlockMetrix from "../../../components/atoms/NibeBlockMetrix/NibeBlockMetrix";
import {convertTimeToHoursMaster} from "../../../functions/TimesFx";

interface MetrixDayWeekCtrlProps{
    className?:string;
    WeekQuery:UseQueryResult<ChronoTask[], Error>;
}
interface Day{
    num:number;
    libelle:string;
    total:number;
}
const dayW = [
    {num:1, libelle:'Lundi'},
    {num:2, libelle:'Mardi'},
    {num:3, libelle:'Mercredi'},
    {num:4, libelle:'Jeudi'},
    {num:5, libelle:'Vendredi'},
    {num:6, libelle:'Samedi'},
    {num:0, libelle:'Dimanche'},
]

const MetrixDayWeekCtrl = ({className, WeekQuery}:MetrixDayWeekCtrlProps)=>{
    const [curr, setCurr] = useState(false)
    const ListDay = useMemo(()=>{
        let list:Day[] = [];
        if(!WeekQuery.isError && !WeekQuery.isLoading && WeekQuery.data){
            dayW.map(i=>{
                let total = 0;
                WeekQuery.data.map(ct=>{
                    if(!!ct.end && (new Date(ct.start)).getDay() === i.num){
                        total+=(new Date(ct.end)).getTime() - (new Date(ct.start)).getTime()
                    }
                    return ct
                })
                const myDay:Day = {num:i.num, libelle:i.libelle, total:total};
                const DayNow = (new Date()).getDay() > 0 ? (new Date()).getDay()+1 : 8;
                const DayOne = myDay.num+1;
                if(DayOne<=DayNow) {
                    list.push(myDay);
                }
                return i
            })
        }
        return list;
    }, [WeekQuery, curr])

    return (
        <NibeBlockMetrix title={"Répartition Semaine"} className={className}>
            {ListDay.map((d:Day, idx:number)=>(
                <div key={`oneDay${idx}`} className={`oneDay`}>
                    <div className={`libDay`}>{d.libelle}</div>
                    <div className={`total timeStyle`}>{convertTimeToHoursMaster(d.total).HourStr}:{convertTimeToHoursMaster(d.total).MinStr}:{convertTimeToHoursMaster(d.total).SecStr}</div>
                </div>
            ))}
        </NibeBlockMetrix>
    )
}

const MetrixDayWeek = styled(MetrixDayWeekCtrl)`
    .oneDay{
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      .total{
        font-size: 22px;
      }
    }
`

export default MetrixDayWeek;