import {createGlobalStyle} from "styled-components";

const GlobalStyle =createGlobalStyle`
    *{
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
      font-family: 'Roboto', sans-serif;
      &::-webkit-scrollbar{
        width: 10px;
      }
      &::-webkit-scrollbar-track{
        background:${props=>props.theme.Neutre};
      }
    }
    body{
      background: ${props=>props.theme.BodyBackground};
      color:${props=>props.theme.Secondary};
    },
    #root{
      margin: 0 auto;
    }
    .timeStyle{
      font-family: 'Wallpoet', cursive;
    }
`

export default GlobalStyle;
