
function ConvertDateToString(dat:Date, type:"start"|"end"){
    const Year = dat.getFullYear();
    const Month = `${0}${dat.getMonth()+1}`.slice(-2);
    const Day = `${0}${dat.getDate()}`.slice(-2);
    const Hour = type === "start" ? "00" : "23";
    const Min = type === "start" ? "00" : "59";
    const Sec = type === "start" ? "00" : "59";
    return `${Year}-${Month}-${Day} ${Hour}:${Min}:${Sec}`;
}
function ConvertDateToStringWH(dat:Date){
    const Year = dat.getFullYear();
    const Month = `${0}${dat.getMonth()+1}`.slice(-2);
    const Day = `${0}${dat.getDate()}`.slice(-2);
    return `${Year}-${Month}-${Day}`;
}

export function getDateWeek(){
    const Now = new Date();
    const numDay = Now.getDay();
    const numDayTo = numDay === 0 ? 7 : numDay;
    const start = new Date(Now.getTime() - 86400*1000*(numDayTo-1));
    const end = new Date(start.getTime() + 86400*1000*(7));
    return {start:ConvertDateToStringWH(start), end:ConvertDateToStringWH(end)}
}

export function getDateWeekN1(){
    const Now = new Date();
    const numDay = Now.getDay();
    const start = new Date(Now.getTime() - 86400*1000*(numDay-8));
    const end = new Date(Now.getTime() - 86400*1000*(8));
    return {start:ConvertDateToString(start, "start"), end:ConvertDateToString(end, "end")}
}

export function getDateMonth(){
    const Now = new Date();
    const Year = Now.getFullYear();
    const Month = `${0}${Now.getMonth()+1}`.slice(-2);
    const MonthNext = `${0}${Now.getMonth()+2}`.slice(-2);
    const start = new Date(`${Year}-${Month}-01`);
    const endA = new Date(`${Year}-${MonthNext}-01`)
    const end = new Date(endA.getTime()-(86400*1000));
    return {start:ConvertDateToString(start, "start"), end:ConvertDateToString(end, "end")}
}

export function convertTimeToHoursMaster(time:number){
    const TimeSec = time/1000;
    const HourExact = TimeSec/3600;
    const Hour = Math.floor(TimeSec/3600)
    const RestH = HourExact - Hour;
    const MinExact = RestH*60;
    const Minute = Math.floor(RestH*60);
    const ResteMin = MinExact - Minute;
    const Seconde = Math.floor(ResteMin*60);
    const HourStr = `${0}${Hour}`.slice(-2);
    const MinStr = `${0}${Minute}`.slice(-2);
    const SecStr = `${0}${Seconde}`.slice(-2);
    return {HourStr, MinStr, SecStr}
}