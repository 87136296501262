import {dataFetcher} from "../../services/dataGetter/dataFetcher";

export async function getAllClient():Promise<Client[]>{
    const res:any = await dataFetcher('clients', {method:'get'});
    return res["hydra:member"];
}

export function addClient(datas:ClientFormData):Promise<Client>{
    return dataFetcher('clients', {
        method:'post',
        body:{
            name:datas.name
        }
    });
}