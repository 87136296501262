import {dataFetcher} from "../../services/dataGetter/dataFetcher";

export async function getAllTaches():Promise<Tache[]>{
    const res:any = await dataFetcher('taches', {method:'get'});
    return res["hydra:member"];
}

export function addTache(datas:TacheFormData):Promise<Tache>{
    return dataFetcher('taches', {
        method:'post',
        body:{
            Projet:`/api/projets/${datas.idProjet}`,
            libelle:datas.libelle
        }
    });
}