import React, {useEffect, useState} from "react";
import styled from "styled-components";

interface NibeClockCtrlProps{
    className?:string;
}

const NibeClockCtrl = ({className}:NibeClockCtrlProps)=>{
    const [time, setTime] = useState(`${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`)
    const RefreshTime = ()=>{
        setTime(`${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`);
    }
    useEffect(()=>{
        const Timer = setInterval(()=>{
            RefreshTime()
        }, 1000)
        return ()=>{
            clearInterval(Timer);
        }
    })
    return (
        <div className={`Clocking ${className}`}>{time}</div>
    )
}

const NibeClock = styled(NibeClockCtrl)``

export default NibeClock;