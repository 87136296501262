import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {addChrono, getChronoCurrent, getCTStart, stopChrono} from "./chronoTask.services";
import {toast} from "react-toastify";


export const useGetChronoCurrent = ():UseQueryResult<ChronoTask|undefined|null, Error>=>{
    return useQuery<ChronoTask|null|undefined, Error>(["chrono_current"], getChronoCurrent, {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}



export const useStopChrono = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return stopChrono(id);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["chrono_current"]);
                queryClient.invalidateQueries(["chronos"]);
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useStartChrono = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ChronoTaskFormData)=>{
            return addChrono(datas);
        },{
            onSuccess:(newChrono:ChronoTask)=>{
                queryClient.setQueryData(["chrono_current"], newChrono);
                queryClient.invalidateQueries(["chronos"]);
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useGetChronoRange = (start:string, end:string):UseQueryResult<ChronoTask[], Error>=>{
    return useQuery<ChronoTask[], Error>(["chronos", start, end], ()=>getCTStart(start, end), {
        staleTime:5000,
        refetchOnWindowFocus:false
    })
}