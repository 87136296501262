import React from "react";
import styled from "styled-components";

interface NibeChipsCtrlProps{
    className?:string;
    objet: { id:number, libelle:string };
    setObjet:Function;
    isSelected:boolean;
}

const NibeChipsCtrl = ({className, objet, setObjet, isSelected}:NibeChipsCtrlProps)=>{
    const handleSwitch = ()=>{
        setObjet(isSelected ? null : objet);
    }
    return (
        <div className={`NibeChips ${className}`}>
            <div className={'in-chips'} onClick={handleSwitch}>
                <div className={`lib`}>{objet.libelle}</div>
            </div>
        </div>
    )
}

const NibeChips = styled(NibeChipsCtrl)`
    padding: 0.25rem;
    .in-chips{
      width: max-content;
      background: ${props=>props.isSelected ? props.theme.Secondary : props.theme.Complementary};
      border : solid 1px ${props=>props.isSelected ? props.theme.Secondary : props.theme.Complementary};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.25rem 0.5rem;
      border-radius: 8px;
      &:hover{
        cursor: pointer;
        background:${props=>props.theme.Secondary}
      }
      .lib{
        text-align: center;
        color:${props=>props.theme.Primary};
        font-weight: bold;
        font-size: 14px;
      }
    }
`

export default NibeChips;