import React from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import {UseQueryResult} from "react-query";
import HeadPage from "./HeadPage";

interface MainLayoutCtrlProps{
    className?:string;
    UserQuery:UseQueryResult<User, Error>;
}

const MainLayoutCtrl = ({className, UserQuery}:MainLayoutCtrlProps)=>{
    return (
        <div className={`MainLayout ${className}`}>
            <HeadPage UserQuery={UserQuery}/>
            <div className={`MainContent`}>
                <Outlet/>
            </div>
        </div>
    )
}
const MainLayout = styled(MainLayoutCtrl)``
export default MainLayout;