import React from 'react';
import logo from './logo.svg';
import './App.css';
import {ThemeProvider} from "styled-components";

import GlobalStyle from "./styles/Global";
import NibetrackTheme from "./styles/NibetrackTheme";
import {QueryClient, QueryClientProvider} from "react-query";
import {Routes, Route, Navigate} from "react-router-dom";
import PrivateRoute from "./components/security/PrivateRoute";
import Application from "./pages/Main/Application";
import PublicRoute from "./components/security/PublicRout";
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();



function App() {
  return (
      <ThemeProvider theme={NibetrackTheme}>
        <GlobalStyle />
          <QueryClientProvider client={queryClient}>
            <div className="App">
                <Routes>
                    <Route element={<PrivateRoute from={"App"}/>}>
                        <Route path="/time_tracker/*" element={<Application />} />
                    </Route>
                    <Route element={<PublicRoute/>}>
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="/login" element={<Login />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
          </QueryClientProvider>
      </ThemeProvider>
  );
}

export default App;
