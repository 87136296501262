import {dataFetcher} from "../../services/dataGetter/dataFetcher";

export async function getChronoCurrent():Promise<ChronoTask|null|undefined>{
    const res:any = await dataFetcher('chrono_taches?exists[end]=false')
    if(res["hydra:member"].length>0){
        return res["hydra:member"][0];
    } else {
       return null;
    }
}

export function stopChrono(id:number):Promise<ChronoTask>{
    return dataFetcher(`chrono_taches/${id}/stop`, {
        method:'post',
        body:{}
    })
}

export function addChrono(datas:ChronoTaskFormData):Promise<ChronoTask>{
    return dataFetcher(`chrono_taches`, {
        method:'post',
        body:{
            tache:`/api/taches/${datas.idTache}`,
            start:datas.start
        }
    })
}

export async function getCTStart(start:string, end:string):Promise<ChronoTask[]>{
    const res:any = await dataFetcher(`chrono_taches?start[after]=${start}&start[before]=${end}`)
    return res["hydra:member"]
}