import React from "react";
import styled, {keyframes} from "styled-components";
import {ImSpinner3} from "react-icons/im";

interface NibeButtonCtrlProps{
    className?:string;
    Size:"sm"|"large"|"xl";
    Disabled?:boolean;
    Pending?:boolean;
    OnClick?:Function;
    type?:"button"|"submit"|"reset";
    text:string;
    themeColor?:"Primary"|"Secondary"|"Tertiary"|"Complementary"|"Neutre";
    Fully?:boolean;
}

const NibeButtonCtrl = ({className, Disabled, Pending, OnClick, type, text}:NibeButtonCtrlProps)=>{
    const handleClick = ()=>{
        if(OnClick) {
            OnClick();
        }
    }
    return (
        <button className={`NibeButton ${className}`} disabled={Disabled} type={type} onClick={handleClick}>
            {Pending ?
              <div className={`wrapLoad`}><ImSpinner3/></div>:
                <span>{text}</span>
            }
        </button>
    )
}

const RotateCenter = keyframes`
    from{
      transform: translate(-50%, -50%) rotate(0deg);
    }
  to{
    transform: translate(-50%, -50%) rotate(360deg);
  }
`

const NibeButton = styled(NibeButtonCtrl)`
  border: none;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: relative;
  .wrapLoad{
    position: absolute;
    display: flex;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    animation: ${RotateCenter} 0.4s infinite linear;
  }
  font-size: ${props=>props.Size === "sm" ? "14px" : props.Size==="large" ? "18px" : "20px"};
  padding-left: ${props => props.Pending ? "0.25rem" : "0.75rem"};
  padding-right: ${props => props.Pending ? "0.25rem" : "0.75rem"};
  width: ${props=>(props.Fully && !props.Pending) ? "100%" : props.Pending ? '30px' : "max-content"};
  height: ${props=>props.Pending ? '30px' :'auto'};
  border-radius: 20px;
  background: ${props=>props.themeColor === "Primary" ? props.theme.Primary :
                    props.themeColor==="Secondary" ? props.theme.Secondary :
                    props.themeColor === "Tertiary" ? props.theme.Tertiary :
                    props.themeColor === "Complementary" ? props.theme.Tertiary : props.theme.Secondary};
  color: ${props=>props.themeColor === "Primary" ? props.theme.NeutreExtraLight :
          props.themeColor==="Secondary" ? props.theme.Primary :
                  props.themeColor === "Tertiary" ? props.theme.Primary :
                          props.themeColor === "Complementary" ? props.theme.Primary : props.theme.Primary};
  &:hover{
    cursor: pointer;
    filter: brightness(90%);
  }
  &:disabled{
    background: ${props=>props.theme.Neutre};
    color: ${props=>props.theme.NeutreDark};
  }
  
`

export default NibeButton;