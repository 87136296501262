import React, {useRef, useState} from "react";
import styled from "styled-components";
import NibeChips from "../../atoms/NibeChips/NibeChips";

interface NibeChoiceChipsCtrlProps{
    className?:string;
    values:{id:number, libelle:string}[];
    setValue:Function;
    valueSelected:{id:number, libelle:string}|null;
    addItem:Function;
}

const NibeChoiceChipsCtrl = ({className, values, setValue, valueSelected, addItem}:NibeChoiceChipsCtrlProps)=>{
    const [libnew, setLibNew] = useState('');
    const refNew = useRef<HTMLInputElement>(null)
    const handleChangeNew = ()=>{
        if(refNew.current){
            setLibNew(refNew.current.value);
        }
    }
    const handleEnter = (e:any)=>{
        if(e.key === 'Enter' && refNew.current && refNew.current.value.length >0){
            addItem(refNew.current.value)
        }
    }
    return (
        <div className={`wrap-nibe-choice ${className}`}>
            {values.map((item:{id:number, libelle:string}, idx:number)=>(
                <NibeChips key={`oneChips${idx}`} objet={item} setObjet={setValue} isSelected={(!!valueSelected && valueSelected.id === item.id)}/>
            ))}
            <div className={`wrap-new-elt`}>
                <input type={"text"} value={libnew} ref={refNew} onChange={handleChangeNew} placeholder={"nouveau"} onKeyPress={handleEnter}/>
            </div>
        </div>
    )
}

const NibeChoiceChips = styled(NibeChoiceChipsCtrl)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  .wrap-new-elt{
    padding: 0.25rem;
    input{
      width: 150px;
      padding: 0.25rem;
      border:solid ${props=>props.theme.Complementary} 1px;
      background: transparent;
      border-radius: 6px;
      color: ${props=>props.theme.Secondary};
    }
  }
`

export default NibeChoiceChips;