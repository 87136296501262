import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import NibeBlockMetrix from "../../../components/atoms/NibeBlockMetrix/NibeBlockMetrix";
import {convertTimeToHoursMaster} from "../../../functions/TimesFx";

interface MetrixTodayCtrlProps{
    className?:string;
    WeekQuery:UseQueryResult<ChronoTask[], Error>
}

const MetrixTodayCtrl = ({className, WeekQuery}:MetrixTodayCtrlProps)=>{
    const [time, setTime] = useState(0);
    const [curr, setCurr] = useState<ChronoTask|null>(null);
    const [timeAdd, setTimeAdd] = useState(0);
    const RefreshTime = ()=>{
        let myTotal = 0;
        let Add = 0;
        if(!WeekQuery.isError && !WeekQuery.isLoading && WeekQuery.data){
            WeekQuery.data.map(i=>{
                if(new Date(i.start).getDate() === (new Date()).getDate()){
                    if(!!i.end){
                        myTotal += (new Date(i.end)).getTime() - (new Date(i.start)).getTime()
                    } else {
                        setCurr(i);
                    }
                }
                return i
            })
        }
        if(curr) {
            Add = ((new Date()).getTime() - (new Date(curr.start)).getTime());
        }
        setTimeAdd(Add);
        setTime(myTotal+Add);
    }
    useEffect(()=>{
        let Timer = setInterval(()=>{
            RefreshTime();
        }, 1000)
        return ()=>{
            clearInterval(Timer)
        }
    },[curr, WeekQuery])
    useEffect(()=>{
        setCurr(null);
        if(!WeekQuery.isError && !WeekQuery.isLoading && WeekQuery.data){
            WeekQuery.data.map(i=>{
                if(!i.end && new Date(i.start).getDate() === (new Date()).getDate()){
                    setCurr(i);
                }
                return i
            })
        }
    }, [WeekQuery])
    return (
        <NibeBlockMetrix className={`BlockMetrix ${className}`} title={"Total Journée"}>
            <div className={`TimeTod`}>
                <p className={`timeStyle`}>{convertTimeToHoursMaster(time).HourStr}:{convertTimeToHoursMaster(time).MinStr}:{convertTimeToHoursMaster(time).SecStr}</p>
            </div>
        </NibeBlockMetrix>
    )
}

const MetrixToday = styled(MetrixTodayCtrl)`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  .TimeTod{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 28px;
    p{
      text-align: center;
    }
  }
`
export default MetrixToday;