import React, {useState} from "react";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import NibeButton from "../../components/atoms/NibeButton/NibeButton";
import NibeInputText from "../../components/atoms/NibeInputText/NibeInputText";
import {login} from "../../features/user/user.services";
import {useNavigate} from "react-router-dom";


interface LoginFormCtrlProps{
    className?:string;
    location:any;
}

const LoginFormCtrl = ({className, ...props}:LoginFormCtrlProps)=>{
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errorConnexion, setErrorConnexion] = useState(false);
    const { register, handleSubmit, formState: { errors, isDirty }, setError, getValues, control, reset } = useForm({
            mode: 'onChange',

        }
    )
    const onSubmit=(data:any)=>{
        if(data.login === ''){
            setError('login', {type:'required', message:'obligatoire'});
        }else if(data.password === ''){
            setError('password', {type:'required', message:'obligatoire'});
        } else {
            setIsSubmitting(true);
            login(data.login, data.password)
                .then((result: any) => {
                    const params = localStorage.getItem("q");
                    let q = "";
                    if(params!=="" && params!==null){
                        q = params.replace(/['"]+/g, "");
                    }
                    localStorage.setItem("__nibe_user_token__", JSON.stringify(result.token).replace(/['"]+/g, ""));
                    //Si l'utilisateur a été déconnecté (token expiré) on le redirige vers la page ou il était, sinon on le redirige vers l'accueil
                    props.location.state ? navigate(props.location.state.from + q) : navigate("/time_tracker");
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    setErrorConnexion(true);
                    if (error.message !== "401Error") {
                        throw error;
                    }
                });
        }

    }
    return (
        <div className={`block ${className}`}>
            {errorConnexion &&
                <div className={`ErrorConnexion`}>Association login/Mot de passe incorrect</div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`BlockEntryConnexion`}>
                    <NibeInputText
                        id={"login"}
                        label={"Login"}
                        type={"text"}
                        register={register}
                        optionRegister={{required:true}}
                        isRequired={true}
                        isError={!!errors.login}
                        helper={errors.login && 'obligatoire'}
                    />
                </div>
                <div className={`BlockEntryConnexion`}>
                    <NibeInputText
                        id={"password"}
                        label={"Mot de passe"}
                        type={"password"}
                        register={register}
                        optionRegister={{required:true}}
                        isRequired={true}
                        isError={!!errors.password}
                        helper={errors.password && 'obligatoire'}
                    />
                </div>
                <div className={`wrap-connexion-btn`}>
                    <NibeButton type={"submit"} Size={"large"} Pending={isSubmitting} text={"Connexion"} Fully={true}/>
                </div>
            </form>
        </div>
    )
}

const LoginForm = styled(LoginFormCtrl)`
  padding: 3.5rem 4rem;
  position: relative;
  .ErrorConnexion{
    position: absolute;
    top: 0;
    left: 0;
    background: ${props=>props.theme.Warning};
    width: 100%;
    padding: 0.25rem;
    color: black;
    text-align: center;
  }
  .BlockEntryConnexion{
    margin-bottom: 15px;
  }
    .wrap-connexion-btn{
      margin-top: 25px;
    }
`

export default LoginForm;