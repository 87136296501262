import {DefaultTheme} from "styled-components";

const NibetrackTheme:DefaultTheme = {
    BodyBackground:"#28072A",
    Primary:"#28072A",
    Secondary:"#CAC3B8",
    Tertiary:"#839A9B",
    Complementary:"#6B8996",

    Neutre:"#939E9E",
    NeutreDark:"#526666",
    NeutreLight:"#B0BEBE",
    NeutreExtraDark:"#3B4D4D",
    NeutreExtraLight:"#E4E6E6",
    Warning:"#e79c2b",
}

export default NibetrackTheme;