import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {FaStop} from "react-icons/fa";
import {useStopChrono} from "../../../features/chronoTask/chronoTask.hooks";

interface CurrentTaskCtrlProps{
    className?:string;
    ChronoTask:ChronoTask;
}
function convertTimeToHours(time:number){
    const TimeSec = time/1000;
    const HourExact = TimeSec/3600;
    const Hour = Math.floor(TimeSec/3600)
    const RestH = HourExact - Hour;
    const MinExact = RestH*60;
    const Minute = Math.floor(RestH*60);
    const ResteMin = MinExact - Minute;
    const Seconde = Math.floor(ResteMin*60);
    const HourStr = `${0}${Hour}`.slice(-2);
    const MinStr = `${0}${Minute}`.slice(-2);
    const SecStr = `${0}${Seconde}`.slice(-2);
    return {HourStr, MinStr, SecStr}
}

const CurrentTaskCtrl = ({className, ChronoTask}:CurrentTaskCtrlProps)=>{
    const mutation = useStopChrono();
    const [Time, setTime] = useState(convertTimeToHours((new Date()).getTime() - (new Date(ChronoTask.start)).getTime()))
    const RefreshTime = ()=>{
        setTime(convertTimeToHours((new Date()).getTime() - (new Date(ChronoTask.start)).getTime()))
    }
    useEffect(()=>{
        const Timex = setInterval(()=>{
            RefreshTime();
        }, 1000)
        return ()=>{
            clearInterval(Timex);
        }
    }, [])
    const stopChrono = ()=>{
        mutation.mutate(ChronoTask.id);
    }
    return (
        <div className={`CurrentTask ${className}`}>
            <div className={`name-task`}>
                {ChronoTask.Tache.Projet.Client.name} {ChronoTask.Tache.Projet.libelle} {ChronoTask.Tache.libelle}
            </div>
            <div className={`time-pasted timeStyle`}>{Time.HourStr}:{Time.MinStr}:{Time.SecStr}</div>
            <div className={`Stop`}>
                <div className={`wrap-stop`} onClick={stopChrono}>
                    <FaStop/>
                </div>
            </div>
        </div>
    )
}

const CurrentTask= styled(CurrentTaskCtrl)`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  padding: 1rem;
  gap:20px;
  border: solid ${props=>props.theme.Secondary} 1px ;
  background: ${props=>props.theme.Complementary};
  color: ${props=>props.theme.Primary};
  border-radius: 4px;
  .name-task{
    font-weight: bold;
  }
  .time-pasted{
    font-size: 26px;
    width: 140px;
    color:${props=>props.theme.Secondary};
  }
  .wrap-stop{
    width: 30px;
    height: 30px;
    background: ${props=>props.theme.Primary};
    color:white;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover{
      cursor: pointer;
    }
    svg{
      margin: auto;
    }
  }
`

export default CurrentTask;