import React from "react";
import styled from "styled-components";
import {useGetChronoRange} from "../../../features/chronoTask/chronoTask.hooks";
import {getDateWeek} from "../../../functions/TimesFx";
import MetrixTotalWeek from "./MetrixTotalWeek";
import MetrixToday from "./MetrixToday";
import MetrixDayWeek from "./MetrixDayWeek";

interface MetrixMainCtrlProps{
    className?:string;
}

const MetrixMainCtrl = ({className}:MetrixMainCtrlProps)=>{
    const ChronoWeekQuery = useGetChronoRange(getDateWeek().start, getDateWeek().end)
    return (
        <div className={`wrapMetrix ${className}`}>
            <div className={`line-metrix`}>
                <MetrixToday WeekQuery={ChronoWeekQuery}/>
                <MetrixTotalWeek WeekQuery={ChronoWeekQuery}/>
            </div>
            <div className={`line-metrix`}>
                <MetrixDayWeek WeekQuery={ChronoWeekQuery}/>
            </div>
        </div>
    )
}

const MetrixMain = styled(MetrixMainCtrl)`
    width: 100%;
    margin: 25px auto;
  .line-metrix{
    display: flex;
    align-items: stretch;
    @media (max-width: 768px) {
      display: block;
    }
  }
    
`

export default MetrixMain;