import React, {useState} from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import NibeClock from "../NibeClock/NibeClock";
import NibeButton from "../../atoms/NibeButton/NibeButton";
import {logout} from "../../../features/user/user.services";

interface HeadPageCtrlProps{
    className?:string;
    UserQuery:UseQueryResult<User, Error>;
}

const HeadPageCtrl = ({className, UserQuery}:HeadPageCtrlProps)=>{
    const [onDisconnect, setOnDisconnect] = useState(false)
    const ClicDisc = ()=>{
        setOnDisconnect(true);
        logout()
    }
    return (
        <div className={`HeadPage ${className}`}>
            <div className={`content-head`}>
                <NibeClock/>
                <div className={`user-in-head`}>
                    <div className={`user-name`}>
                        {UserQuery.isLoading ? 'chargement' : UserQuery.data?.Prenom}
                    </div>
                    <div className={`wrap-logout`}>
                        <NibeButton Size={"sm"} text={"déconnexion"} themeColor={"Primary"} Pending={onDisconnect} OnClick={ClicDisc}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const HeadPage = styled(HeadPageCtrl)`
    width: 100%;
  padding: 0.75rem;
  background: ${props=>props.theme.Secondary};
  color: ${props=>props.theme.Primary};
  .content-head{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .user-in-head{
    display: flex;
    justify-content: end;
    align-items: center;
    .user-name{
      margin-right: 15px;
      font-weight: bold;
    }
  }
`

export default HeadPage;