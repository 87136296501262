import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {addTache, getAllTaches} from "./tache.services";
import {addClient} from "../client/client.services";
import {toast} from "react-toastify";

export const useGetAllTache=():UseQueryResult<Tache[], Error>=>{
    return useQuery<Tache[], Error>(["taches"], getAllTaches, {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useAddTache = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:TacheFormData)=>{
            return addTache(datas);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["taches"]);
            },
            onError:(error:any)=>{
                toast.error("Erreur : "+error.message)
            }
        }
    )
}