import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {addProjet, getAllProjet} from "./projet.services";
import {toast} from "react-toastify";

export const useGetAllProjet = ():UseQueryResult<Projet[], Error>=>{
    return useQuery<Projet[], Error>(["projets"], getAllProjet, {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useAddProjet = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:ProjetFormData)=>{
        return addProjet(datas);
    },{
        onSuccess:()=>{
            queryClient.invalidateQueries(["projets"]);
        },
        onError:(error:any)=>{
            toast.error("Erreur : " + error.message);
        }
    })
}