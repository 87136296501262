import {dataFetcher} from "../../services/dataGetter/dataFetcher";

export async function getAllProjet():Promise<Projet[]>{
    const res:any = await dataFetcher('projets', {method:'get'});
    return res["hydra:member"];
}

export function addProjet(datas:ProjetFormData):Promise<Projet>{
    return dataFetcher('projets', {
        method:'post',
        body:{
            client:`/api/clients/${datas.idClient}`,
            libelle:datas.libelle
        }
    })
}