import React from "react";
import styled from "styled-components";
import {useGetChronoCurrent} from "../../features/chronoTask/chronoTask.hooks";
import StartingTask from "./Starting/StartingTask";
import CurrentTask from "./Current/CurrentTask";
import MetrixMain from "./Metrix/MetrixMain";

interface DashboardCtrlProps{
    className?:string;
}

const DashboardCtrl = ({className}:DashboardCtrlProps)=>{
    const {isLoading:loadCurr, isError:errCurr, data:ChronoCurr} = useGetChronoCurrent();
    return (
        <div className={`Dashboard ${className}`}>

            {loadCurr ? 'chargement': errCurr ? 'erreur' :
                (ChronoCurr === null || ChronoCurr === undefined) ?
                    <StartingTask/>:
                    <CurrentTask ChronoTask={ChronoCurr}/>
            }
            <MetrixMain/>
        </div>
    )
}
const Dashboard = styled(DashboardCtrl)`
    padding: 1rem;
`
export default Dashboard;