import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import {convertTimeToHoursMaster} from "../../../functions/TimesFx";
import NibeBlockMetrix from "../../../components/atoms/NibeBlockMetrix/NibeBlockMetrix";

interface MetrixTotalWeekCtrlProps{
    className?:string;
    WeekQuery:UseQueryResult<ChronoTask[], Error>
}
interface LineClient{
    id:number;
    libelle:string;
    total:number;
}

const MetrixTotalWeekCtrl = ({className, WeekQuery}:MetrixTotalWeekCtrlProps)=>{
    const [time, setTime] = useState(0);
    const [curr, setCurr] = useState<ChronoTask|null>(null);
    const [timeAdd, setTimeAdd] = useState(0);
    const RefreshTime = ()=>{
        let myTotal = 0;
        let Add = 0;
        if(!WeekQuery.isError && !WeekQuery.isLoading && WeekQuery.data){
            WeekQuery.data.map(i=>{
                if(!!i.end){
                    myTotal += (new Date(i.end)).getTime() - (new Date(i.start)).getTime()
                } else {
                    setCurr(i);
                }
                return i
            })
        }
        if(curr) {
            Add = ((new Date()).getTime() - (new Date(curr.start)).getTime());
        }

        setTimeAdd(Add);
        setTime(myTotal+Add);
    }
    useEffect(()=>{
        let Timer = setInterval(()=>{
            RefreshTime();
        }, 1000)
        return ()=>{
            clearInterval(Timer)
        }
    },[curr, WeekQuery])
    useEffect(()=>{
        let myTotal = 0;
        setCurr(null);
        if(!WeekQuery.isError && !WeekQuery.isLoading && WeekQuery.data){
            WeekQuery.data.map(i=>{
                if(!!i.end){
                    myTotal += (new Date(i.end)).getTime() - (new Date(i.start)).getTime()
                } else {
                    setCurr(i);
                }
                return i
            })
        }
        setTime(myTotal);
    }, [WeekQuery])
    const ListClient = useMemo(()=>{
        let List:LineClient[] = [];
        if(!WeekQuery.isLoading && !WeekQuery.isError && WeekQuery.data){
            const ListClientD:Client[] = WeekQuery.data.map(i=>i.Tache.Projet.Client);
            let LClientSSD:Client[] = [];
            ListClientD.map(i=>{
                const idClient = LClientSSD.map(c=>c.id);
                if(idClient.indexOf(i.id)===-1){
                    LClientSSD.push(i);
                }
                return i;
            })
            LClientSSD.map(i=>{
                let time = 0;
                WeekQuery.data.map(c=>{
                    if(c.Tache.Projet.Client.id === i.id && !!c.end){
                        time+=(new Date(c.end)).getTime() - (new Date(c.start)).getTime();
                    }
                    return c;
                })
                List.push({id:i.id, total:time, libelle:i.name})
            })
        }
        return List
    }, [WeekQuery])
    return (
        <NibeBlockMetrix className={`BlockMetrix ${className}`} title={"Total Semaine"}>
            <div className={`totalweek timeStyle`}>
                {convertTimeToHoursMaster(time).HourStr}:{convertTimeToHoursMaster(time).MinStr}:{convertTimeToHoursMaster(time).SecStr}
            </div>
            <div className={`wrap-list`}>
                {ListClient.map((i:LineClient, idx:number)=>(
                    <div className={`lineClient`} key={`oneClient${idx}`}>
                        <div className={`libClient`}>{i.libelle}</div>
                        <div className={`Total timeStyle`}>
                            {convertTimeToHoursMaster(i.total +(i.id === curr?.Tache.Projet.Client.id ? timeAdd : 0)).HourStr}:{convertTimeToHoursMaster(i.total +(i.id === curr?.Tache.Projet.Client.id ? timeAdd : 0)).MinStr}:{convertTimeToHoursMaster(i.total +(i.id === curr?.Tache.Projet.Client.id ? timeAdd : 0)).SecStr}
                        </div>
                    </div>
                ))}
            </div>
        </NibeBlockMetrix>
    )
}

const MetrixTotalWeek = styled(MetrixTotalWeekCtrl)`
    width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  .totalweek{
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
  }
  .lineClient{
    display: flex;
    gap:10px;
    align-items: center;
  }
`

export default MetrixTotalWeek;