import React, {useEffect, useMemo, useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
import {useAddClient, useGetAllClient} from "../../../features/client/client.hooks";
import {useAddProjet, useGetAllProjet} from "../../../features/projet/projet.hooks";
import {useAddTache, useGetAllTache} from "../../../features/tache/tache.hooks";
import NibeChoiceChips from "../../../components/molecules/NibeChoiceChips/NibeChoiceChips";
import NibeChips from "../../../components/atoms/NibeChips/NibeChips";
import {FaPlay} from "react-icons/fa";
import {useMutation} from "react-query";
import {addProjet} from "../../../features/projet/projet.services";
import {useStartChrono} from "../../../features/chronoTask/chronoTask.hooks";
import NibeButton from "../../../components/atoms/NibeButton/NibeButton";

interface StartingTaskCtrlProps{
    className?:string;
}
export interface choice{
    id:number;
    libelle:string;
}
function getHourNow(){
    const DateNow = new Date();
    const Year = DateNow.getFullYear();
    const Month = `${0}${DateNow.getMonth()+1}`.slice(-2);
    const Day = `${0}${DateNow.getDate()}`.slice(-2);
    const Hour = `${0}${DateNow.getHours()}`.slice(-2);
    const Min = `${0}${DateNow.getMinutes()}`.slice(-2);
    const Sec = `${0}${DateNow.getSeconds()}`.slice(-2);
    return `${Year}-${Month}-${Day} ${Hour}:${Min}:${Sec}`;
}

function ConvertHour(dat:Date){
    const Year = dat.getFullYear();
    const Month = `${0}${dat.getMonth()+1}`.slice(-2);
    const Day = `${0}${dat.getDate()}`.slice(-2);
    const Hour = `${0}${dat.getHours()-2}`.slice(-2);
    const Min = `${0}${dat.getMinutes()}`.slice(-2);
    const Sec = `${0}${dat.getSeconds()}`.slice(-2);
    return `${Year}-${Month}-${Day} ${Hour}:${Min}:${Sec}`;
}


const StartingTaskCtrl =({className}:StartingTaskCtrlProps)=>{
    const mutationAddProj = useAddProjet();
    const mutationAddClient = useAddClient();
    const mutationAddTache = useAddTache();
    const mutationAddChrono = useStartChrono();
    const [client, setClient] = useState<choice|null>(null);
    const [projet, setProjet] = useState<choice|null>(null);
    const [tache, setTache] = useState<choice|null>(null);
    const [onStart, setOnStart] = useState(false);
    const ClientQuery = useGetAllClient();
    const ProjetQuery = useGetAllProjet();
    const TacheQuery = useGetAllTache();
    const refStart = useRef<HTMLInputElement>(null);
    const refEnd = useRef<HTMLInputElement>(null);
    const [manualStart, setManualStart] = useState('');
    const [manualEnd, setManualEnd] = useState('');
    useEffect(()=>{
        if(client === null){
            setProjet(null);
            setTache(null);
        } else if(projet===null){
            setTache(null);
        }
    }, [client, projet, tache])
    const ClientList = useMemo(()=>{
        let clientL:choice[] = [];
        if(!ClientQuery.isLoading && !ClientQuery.isError && ClientQuery.data){
            clientL = ClientQuery.data.map(c=>{
                return {id:c.id, libelle:c.name};
            })
        }
        return clientL;
    }, [ClientQuery])
    const ProjetList = useMemo(()=>{
        let projetL:choice[] = [];
        if(!ProjetQuery.isLoading && !ProjetQuery.isError && ProjetQuery.data && client){
            projetL = ProjetQuery.data.filter(p=> {
                  return p.Client.id === client.id
                }
            ).map(p=>{
                return {id:p.id, libelle:p.libelle}
            })
        }
        return projetL;
    }, [ProjetQuery,client])
    const TacheList = useMemo(()=>{
        let tacheL:choice[] = [];
        if(!TacheQuery.isLoading && !TacheQuery.isError && TacheQuery.data && projet && client){
            tacheL = TacheQuery.data.filter(t=>t.Projet.id === projet.id).map(t=>{
                return {id:t.id, libelle:t.libelle};
            })
        }
        return tacheL;
    }, [TacheQuery, projet, client])
    const AddProjet =(libelle:string)=>{
        if(!!client) {
            const datas: ProjetFormData = {
                idClient: client.id,
                libelle:libelle
            }
            mutationAddProj.mutate((datas), {
                onSuccess:(newProjet)=>{
                    setProjet({id:newProjet.id, libelle:newProjet.libelle});
                }
            })
        }
    }
    const AddClient = (name:string)=>{
        const datas:ClientFormData={
            name:name
        }
        mutationAddClient.mutate((datas), {
            onSuccess:(newClient)=>{
                setClient({id:newClient.id, libelle:newClient.name});
            }
        })
    }
    const AddTache = (libelle:string)=>{
        if(!!projet){
            const datas:TacheFormData={
                idProjet:projet.id,
                libelle:libelle
            }
            mutationAddTache.mutate((datas), {
                onSuccess:(newTache)=>{
                    setTache({id:newTache.id, libelle:newTache.libelle});
                }
            })
        }
    }
    const StartChrono = ()=>{
        if(!!tache && !onStart) {
            setOnStart(true);
            const myStart = manualStart !=='' ? ConvertHour(new Date(manualStart)) : getHourNow();
            const datas: ChronoTaskFormData = {
                idTache:tache.id,
                start:myStart
            }
            mutationAddChrono.mutate((datas), {
                onSuccess:()=>{
                    setOnStart(false);
                }
            })
        }
    }
    const handleChangeStartManual = ()=>{
        if(refStart.current){
            console.log('ici>>'+refStart.current.value)
            setManualStart(refStart.current.value + ':00Z');
        }
    }
    const handleChangeEndManual = ()=>{
        if(refEnd.current){
            setManualEnd(refEnd.current.value);
        }
    }
    return (
        <div className={`Starter ${className}`}>
            {(ClientQuery.isLoading || ProjetQuery.isLoading || TacheQuery.isLoading) ? 'chargement des données' :
                (ClientQuery.isError || ProjetQuery.isError || TacheQuery.isError) ? 'Erreur de chargement':
                    <div className={`wrap-choice`}>
                        {client === null ?
                            <NibeChoiceChips values={ClientList} setValue={setClient} valueSelected={client} addItem={AddClient}/> :
                            <NibeChips isSelected={true} objet={client} setObjet={setClient}/>
                        }
                        {(projet === null && client!==null) ?
                            <NibeChoiceChips values={ProjetList} setValue={setProjet} valueSelected={projet} addItem={AddProjet}/> :
                            !!projet ?
                            <NibeChips isSelected={true} objet={projet} setObjet={setProjet}/>:''
                        }
                        {(tache === null && !!projet) ?
                            <NibeChoiceChips values={TacheList} setValue={setTache} valueSelected={tache} addItem={AddTache}/> :
                            !!tache?
                            <NibeChips isSelected={true} objet={tache} setObjet={setTache}/>:''
                        }
                        {(client!==null && !!projet && !!tache) &&
                            <div className={`play-me`}>
                                <div className={`manualstart`}>
                                    <input ref={refStart} type={"datetime-local"} onChange={handleChangeStartManual} value={(manualStart || '').toString().substring(0, 16)}/>
                                </div>
                                <div className={`play-btn ${onStart ? 'onStart' : ''}`} onClick={StartChrono}>
                                    <FaPlay/>
                                </div>
                                {manualStart!=='' &&
                                    <div className={`manualend`}>
                                        <input ref={refEnd} type={"datetime-local"} onChange={handleChangeEndManual} value={manualEnd}/>
                                        <NibeButton type={"button"} themeColor={"Secondary"} text={"Enregistrer"} Size={"sm"}/>
                                    </div>
                                }
                            </div>
                        }
                    </div>
            }
        </div>
    )
}
const RotateMe = keyframes`
    from{
      transform: rotate(0deg);
    }
  to{
    transform: rotate(360deg);
  }
`

const StartingTask = styled(StartingTaskCtrl)`
    .wrap-choice{
      display: flex;
      gap:5px;
      align-items: center;
    }
  .play-me{
    display: flex;
    gap:10px;
    input{
      background: transparent;
      color: ${props=>props.theme.Secondary};
      padding: 0.50rem 0.25rem;
      border:solid ${props=>props.theme.Secondary} 1px;
      border-radius: 6px;
    }
    
  }
  .manualend{
    display: flex;
    gap: 10px;
  }
  .play-btn{
    width: 40px;
    height: 40px;
    border: ${props=>props.theme.Secondary} solid 1px;
    color:${props=>props.theme.Secondary};
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.onStart{
      animation: ${RotateMe} 0.4s infinite linear;
    }
    &:not(.onStart):hover{
      cursor: pointer;
    }
    svg{
      margin: auto;
    }
  }
`

export default StartingTask;