import React from "react";
import styled from "styled-components";
import {RegisterOptions} from "react-hook-form";

interface NibeInputTextCtrlProps{
    className?:string;
    label:string;
    type:"text" | "date" | "datetime" | "number" | "password" |"time";
    isRequired?:boolean;
    isError:boolean;
    register: any;
    optionRegister?:RegisterOptions;
    id:string;
    size?:"sm"|"std";
    Fully?:boolean;
    helper?:string;
    themeColor?:"Primary"|"Secondary"|"Tertiary"|"Complementary"|"Neutre";
}

const NibeInputTextCtrl = ({className, label, type, isRequired, helper, register, optionRegister, id}:NibeInputTextCtrlProps)=>{
    return (
        <div className={`block-nibe-input ${className}`}>
            <label>{label}{isRequired && '*'}</label>
            <input type={type} id={id} {...register(id, optionRegister)}/>
            {helper &&
                <div className={`helper`}>{helper}</div>
            }
        </div>
    )
}
const NibeInputText = styled(NibeInputTextCtrl)`
  width: 100%;
  label{
    color:${props=>props.themeColor === "Primary" ? props.theme.Primary :
            props.themeColor==="Secondary" ? props.theme.Secondary :
                    props.themeColor === "Tertiary" ? props.theme.Tertiary :
                            props.themeColor === "Complementary" ? props.theme.Tertiary : props.theme.Secondary};
    font-weight: bold;
    display: block;
    margin-bottom: 4px;
    font-size: ${props=>props.size==="sm" ? "16px" : "18px"};
  }
  input{
    padding: ${props=>props.size === "sm" ? "0.25rem 0.5rem" : "0.5rem 0.5rem"};
    border-width: 1px;
    border-style: solid;
    border-color:${props=>props.isError ? 'red' :props.themeColor === "Primary" ? props.theme.Primary :
            props.themeColor==="Secondary" ? props.theme.Secondary :
                    props.themeColor === "Tertiary" ? props.theme.Tertiary :
                            props.themeColor === "Complementary" ? props.theme.Tertiary : props.theme.Secondary};
    border-radius: 4px;
  }
  .helper{
    padding: 0.25rem 0;
    font-size: 14px;
    color:${props=>props.isError ? props.theme.Warning : props.themeColor === "Primary" ? props.theme.Primary :
            props.themeColor==="Secondary" ? props.theme.Secondary :
                    props.themeColor === "Tertiary" ? props.theme.Tertiary :
                            props.themeColor === "Complementary" ? props.theme.Tertiary : props.theme.Secondary};
  }
`
export default NibeInputText;