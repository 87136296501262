import {dataFetcher, removeToken} from "../../services/dataGetter/dataFetcher";

/**
 * Permet de se connecter via l'adresse mail et le mot de passe, retourne un objet content le jwt.
 * @param username
 * @param password
 */
export function login(username: string, password: string) {
    return dataFetcher("login", {
        method: "POST",
        body: {
            username: username,
            password: password,
        },
    });
}

export function logout(){
    removeToken()
}

export function getInfosUser():Promise<User>{
    return dataFetcher(`users/me`, {
        method:'get',
    })
}