import React from "react";
import styled from "styled-components";

interface NotFoundCtlProps{
    className?:string;
}

const NotFoundCtl = ({className}:NotFoundCtlProps)=>{
    return (
        <div className={`NotFoundPage ${className}`}>
            <p>po trouvé</p>
        </div>
    )
}
const NotFound = styled(NotFoundCtl)``
export default NotFound;